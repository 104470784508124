import Grid, { GridSpacing } from "@material-ui/core/Grid";
import Icon from "@material-ui/core/Icon";
import {FaLinkedinIn, FaFileAlt} from "react-icons/fa";
import {FiMail, FiBriefcase} from "react-icons/fi"
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";

interface ContactInfoProps {
    spacing: number
}

const styles = makeStyles((theme: Theme) => createStyles({
    grid: {
        justifyContent: "center",
    },
    icon: {
        color: "#025d96",
        fontSize: "35px"
    }
}));

const ContactInfo = (props: ContactInfoProps) : JSX.Element => {
    const classes = styles();

    return (
        <Grid container spacing={props.spacing as GridSpacing} className={classes.grid}>
            <Grid item>
                <a href={"https://twitter.com/KathreinLab"} target={"_blank"}>
                    <Icon className={classes.icon}><FiBriefcase/></Icon>
                </a>
            </Grid>
            <Grid item>
                <a href={"mailto:alyssaclark1008@gmail.com"} target={"_blank"}>
                    <Icon className={classes.icon}><FiMail/></Icon>
                </a>
            </Grid>
            <Grid item>
                <a href={"https://www.linkedin.com/in/alyssa-clark-629b60185/"} target={"_blank"}>
                    <Icon className={classes.icon}><FaLinkedinIn/></Icon>
                </a>
            </Grid>
            <Grid item>
                <a href={"https://drive.google.com/file/d/123Odz1mUFxkn6W8rBzZfpAVBPYKgG3_z/view?usp=sharing"} target={"_blank"}>
                    <Icon className={classes.icon}><FaFileAlt/></Icon>
                </a>
            </Grid>
        </Grid>
    );
};

export default ContactInfo;