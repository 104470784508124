import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Typography from "@material-ui/core/Typography";
import React from "react";

const styles = makeStyles((theme: Theme) => createStyles({
    box: {
        textAlign: "center"
    },
    card: {
        backgroundColor: "#f1f1f1"
    }
}));

const Research = (): JSX.Element => {
    const classes = styles();

    return (
        <Box className={classes.box}>
            <Card className={classes.card}>
                <CardHeader
                    title={"Research"}
                />
                <CardContent>
                    <Typography>
                       In Dr. Kathrein's Lab I am working on the Ing4 knockdown in Zebrafish embryos.
                    </Typography>
                    <Typography>
                        We use plasmids cultured from bacteria as probes to look at specialized blood cells.
                    </Typography>
                    <Typography>
                        Using special needles, we inject Ing4 into the embryos and check their growth with stains and probes after 36 hours of growth.
                    </Typography>
                </CardContent>
            </Card>
        </Box>    );
};

export default Research;