import React from 'react';
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Avatar from './Avatar'
import ContactInfo from "./ContactInfo";
import BackgroundImage from "../static/science-background.png";

const styles = makeStyles((theme: Theme) => createStyles({
        root: {
          flexGrow: 1,
        },
        card: {
            width: "30%",
            marginLeft: "35%",
            '@media (max-width: 414px)': {
                marginLeft: "15%",
                width: "70%",
            }
        },
        box: {
            alignItems: "center",
            justifyContent: "center",
            alignContent: "center",
            alignSelf: "center",
            textAlign: "center",
            backgroundImage: `url(${BackgroundImage})`,
            paddingBottom: "25px",
            paddingTop: "25px",
        },
        header: {
            alignItems: "center"
        },
        title: {
            fontSize: "15",
            alignSelf: "center"
        },
    }),
);

/**
 * Displays the Title Card of the page, in this case the Personal Information
 * @returns { JSX.Element }
 * @constructor
 */
const PersonalInformation = (): JSX.Element => {
    const classes = styles();
    return (
        <Box className={classes.box}>
            <Avatar/>
            <Card className={classes.card}>
                <CardHeader
                    className={classes.header}
                    title={"Alyssa Clark"}
                    subheader={"Undergraduate Research Assistant"}
                />
                <CardContent>
                    <ContactInfo spacing={1}/>
                </CardContent>
            </Card>
        </Box>
    );
};

// Pushes the current model up the chain to the parent
export default PersonalInformation;
