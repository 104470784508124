import React from 'react';
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";


const styles = makeStyles((theme: Theme) => createStyles({
    box: {
        textAlign: "center"
    },
    card: {
        backgroundColor: "#f9f9f9"
    }
}));

const AboutSection = (): JSX.Element => {
    const classes = styles();
    return (
        <Box className={classes.box}>
            <Card className={classes.card}>
                <CardHeader
                    title={"About"}
                />
                <CardContent>
                    <Typography>
                        I'm a Senior Biochemistry and Molecular Biology Major at the University of South Carolina and will graduate in May of 2020 with Honors
                        and Leadership Distinction in Research.
                    </Typography>
                    <Typography>
                        I am currently working with Dr. Katie Kathrein on my Honors Senior Thesis.
                    </Typography>
                    <Typography>
                        In my free time, I like to read, write, and do crafts.
                        I'm as much of a fan of the arts as I am of the sciences.
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    )
};

export default AboutSection;