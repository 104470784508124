import React from "react";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const styles = makeStyles((theme: Theme) => createStyles({
    avatar: {
        left: "35%",
        width: "30%",
        height: "auto",
        justifyContent: "center",
        alignSelf: "center",
        '@media (max-width: 414px)': {
            left: "20%",
            width: "60%",
        }
    },
    box: {
        width: "100%",
        height: "auto",
        paddingTop: "10px",
        paddingBottom: "10px",
    },
}));

const AvatarBox = (): JSX.Element => {
    const classes = styles();
    return (
        <Box className={classes.box}>
            <Avatar
                className={classes.avatar}
                src={"https://media.licdn.com/dms/image/C4D03AQEm9NmiMGhmvA/profile-displayphoto-shrink_800_800/0?e=1574899200&v=beta&t=0rz_M0EXpL5zUSvrnriJQCQmiYe1nz8pNidIVxuT3-Y"}
            />
        </Box>
    )
};

export default AvatarBox;