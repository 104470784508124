import React from 'react';
import Research from "./components/Research";
import TitleCard from './components/TitleCard'
import AboutSection from './components/AboutSection'
import ContactSection from './components/ContactSection'

const App: React.FC = () => {
  return (
      <div>
          {/*<CustomNavBar/>*/}
          <TitleCard/>
          <AboutSection/>
          <Research/>
          <ContactSection/>
      </div>
  );
};

export default App;
