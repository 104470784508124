import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {createStyles, Theme} from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import ContactInfo from "./ContactInfo";


const styles = makeStyles((theme: Theme) => createStyles({
    box: {
        textAlign: "center",
    },
    card: {
        backgroundColor: "#f9f9f9"
    }
}));

const ContactSection = () => {
    const classes = styles();
    return (
        <Box className={classes.box}>
            <Card className={classes.card}>
                <CardHeader
                    title={"Connect with Me"}
                />
                <CardContent>
                    <ContactInfo spacing={5}/>
                </CardContent>
            </Card>
        </Box>
    )
};

export default ContactSection;